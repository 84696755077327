import { eventBus } from "@/eventBus";
import { store } from "@/store/store";
import { mapGetters, mapActions } from "vuex";
import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/database";

const PlaybackUtilities = {
  computed: {
    ...mapGetters(["appConfig", "profileid", "purchaseList", "availabilityList", "subscriptionList", "getDisplayLanguage"]),
  },

  methods: {
    ...mapActions(["authorizeCurrentStreamApi", "setCurrentStreamApi"]),
    // showPlayer() {
    //   this.playerInstance.blowUpPlayer();

    //   this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
    //   this.playerInstance.registerEvent("loadchosencontent", this.loadChosenContentData);
    //   this.playerInstance.registerEvent("loadmorecontent", this.loadMoreContentData);

    //   this.playbackInitiated = true;
    //   eventBus.$emit("player-visibility", true);
    // },
    hidePlayer() {
      this.playerInstance.shrinkPlayer();

      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };
      eventBus.$emit("load-player-content", payload);

      this.playerInstance.unloadPlayer(true);
    },
    //Live Player
    loadChosenIndexContentLiveStream(episodeIndex, episodeList, totalCount, isFirstTime = true, program=null) {
      return new Promise ((resolve, reject) => {
        eventBus.$emit("loader-player-show", true);
        this.fetchContentData(episodeList[episodeIndex], this.isMpegRequired).then((resp) => {
            if (typeof resp == "object" && !resp.data.errorcode) {
              episodeList[episodeIndex].title = resp.data.title;
              episodeList[episodeIndex].contentdetails = resp.data.contentdetails;
              episodeList[episodeIndex].seriesname = resp.data.seriesname;
              episodeList[episodeIndex].poster = resp.data.poster;
              let nextepisodeid = episodeList[episodeIndex + 1] ? episodeList[episodeIndex + 1].objectId : "";
              localStorage.setItem("nextepisodeid", nextepisodeid);
            } else if (typeof resp == "boolean" && resp == true) {
              
              eventBus.$emit("loader-player-show", false);
            } else {
              let playbackError = true;              
              if (typeof resp == "object") {
                playbackError = resp.data;
              }
              eventBus.$emit("loader-player-show", false);
              throw playbackError;
            }            
            let isPlaybackAllowed = this.checkAvailabilityAllowsPlayback(episodeList[episodeIndex]);
            if (!isPlaybackAllowed) throw isPlaybackAllowed;
            let singleTag = this.getStreamUrlByTags(episodeList[episodeIndex]);
            if(program && program.start && program.stop){
             // In future we have to add startdate and stop date
             let programStart = moment.utc(moment(program.start, "YYYYMMDDHHmmss Z")).local().format("YYYY-MM-DD HH:mm:ss");
             let startDay = moment(programStart).format("YYYYMMDD");
             let startTime = moment(programStart).format("HHmmss");
             let programEnd  = moment.utc(moment(program.stop, "YYYYMMDDHHmmss Z")).local().format("YYYY-MM-DD HH:mm:ss");
             let endDay = moment(programEnd).format("YYYYMMDD");
             let endTime = moment(programEnd).format("HHmmss");
              singleTag = `${singleTag}?begin=${startDay}T${startTime}&end=${endDay}T${endTime}`;
            }
            if(this.appConfig.featureEnabled.tagBasedStream && singleTag){
              let payloaddatabase = {}
              payloaddatabase.streamUrl = singleTag
              payloaddatabase.idepgchannel = episodeList[0].idepgchannel;
              payloaddatabase.title = episodeList[0].title;
              payloaddatabase.contentAccess = episodeList[0];
              payloaddatabase.objectid = episodeList[0].objectid;
              resolve(payloaddatabase);
              return;
            } else {  
              this.fetchContentStreamUrl(episodeList[episodeIndex], this.isMpegRequired).then((resp) => {
                  let loadPlayer = false;
                  eventBus.$emit("loader-player-show", false)
                    let payloaddatabase = {}
                    payloaddatabase.streamUrl = resp.data.streamfilename
                    payloaddatabase.idepgchannel = episodeList[0].idepgchannel;
                    payloaddatabase.title = episodeList[0].title;
                    payloaddatabase.contentAccess = episodeList[0];
                    payloaddatabase.objectid = episodeList[0].objectid;
    
    
    
                    // eventBus.$emit("live-player-active", payloaddatabase);
                    resolve(payloaddatabase);
                    
              }).catch((err) => {
                
                reject({status: 'playbackError'})
              })
            }

          }).catch((err)=>{
            // alert("error");
            reject({status: 'playbackError'})
            
          });
      })
    },

    loadChosenIndexContent(episodeIndex, episodeList, totalCount, isFirstTime = true) {
      this.fetchContentDetails(episodeList[episodeIndex], this.isMpegRequired)
        .then((resp) => {
          

          if (typeof resp == "object" && !resp.data.errorcode) {
            episodeList[episodeIndex].title = resp.data.title;
            episodeList[episodeIndex].contentdetails = resp.data.contentdetails;
            episodeList[episodeIndex].seriesname = resp.data.seriesname;
            episodeList[episodeIndex].poster = resp.data.poster;
            let nextepisodeid = episodeList[episodeIndex + 1] ? episodeList[episodeIndex + 1].objectId : "";
            localStorage.setItem("nextepisodeid", nextepisodeid);
          } else if (typeof resp == "boolean" && resp == true) {
            
          } else {
            let playbackError = true;

            if (typeof resp == "object") {
              playbackError = resp.data;
            }
            throw playbackError;
          }

          let isPlaybackAllowed = this.checkAvailabilityAllowsPlayback(episodeList[episodeIndex]);

          if (!isPlaybackAllowed) throw isPlaybackAllowed;

          this.fetchContentStreamUrl(episodeList[episodeIndex], this.isMpegRequired)
            .then((resp) => {
              let loadPlayer = false;

              if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
                // Call the Stream Authorize API
                this.authorizeCurrentStreamApi()
                  .then((authResponse) => {
                    

                    if (authResponse.data.success) {
                      if (typeof resp == "object" && !resp.data.errorcode) {
                        this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
                        loadPlayer = true;
                      } else if (typeof resp == "boolean" && resp == true) {
                        loadPlayer = true;
                      }

                      if (loadPlayer == true) {
                        this.loadContentMetaData(episodeList[episodeIndex], null);

                        if (typeof resp == "object" || totalCount == 1) {
                          if (isFirstTime == true) {
                            let playlist = this.buildPlayerPlaylist(episodeList, 0);
                            this.playerInstance.loadPlaylist(playlist, totalCount);
                          } else {
                            let playlist = this.playerInstance.playlist;
                            let updatedContentItem = this.createPlayerPlaylistItem(episodeList[episodeIndex], 0);
                            playlist.updateItem(episodeIndex, updatedContentItem);
                          }
                        }

                        this.playerInstance.loadContent(episodeIndex).then(() => {
                          this.playerInstance.play().then(() => {
                            

                            const payload = {
                              contentId: episodeList[episodeIndex].objectid,
                              params: {
                                devicetype: "PC",
                              },
                            };

                            this.setCurrentStreamApi(payload).then((setStreamResponse) => {
                              console.log("THE SET STREAM API RESP === > ", setStreamResponse);
                            });
                          });
                        });
                      } else {
                        let playbackError = true;

                        if (typeof resp == "object") {
                          playbackError = resp.data;
                        }

                        if(resp.data.errorcode === 9902) {
                          this.hidePlayer();
  
                          eventBus.$emit("showAuthorizeErrorMessage", {
                            state: true,
                            messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                          });
                        }


                        throw playbackError;
                      }
                    } else {
                      let playbackError = true;
                      
                      if (typeof resp == "object") {
                        playbackError = resp.data;
                      }

                      this.hidePlayer();

                      eventBus.$emit("showAuthorizeErrorMessage", {
                        state: true,
                        messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                      });

                      throw playbackError;
                    }
                  })
                  .catch((err) => {
                    let playbackError = true;
                    
                    if (typeof resp == "object") {
                      playbackError = resp.data;
                    }

                    this.hidePlayer();

                    eventBus.$emit("showAuthorizeErrorMessage", {
                      state: true,
                      messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                    });

                    throw playbackError;
                  });
              }
              // If the concurrency APIs are disabled
              else {
                if (typeof resp == "object" && !resp.data.errorcode) {
                  this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
                  loadPlayer = true;
                } else if (typeof resp == "boolean" && resp == true) {
                  loadPlayer = true;
                }

                if (loadPlayer == true) {
                  this.loadContentMetaData(episodeList[episodeIndex], null);

                  if (typeof resp == "object" || totalCount == 1) {
                    if (isFirstTime == true) {
                      let playlist = this.buildPlayerPlaylist(episodeList, 0);
                      this.playerInstance.loadPlaylist(playlist, totalCount);
                    } else {
                      let playlist = this.playerInstance.playlist;
                      let updatedContentItem = this.createPlayerPlaylistItem(episodeList[episodeIndex], 0);
                      playlist.updateItem(episodeIndex, updatedContentItem);
                    }
                  }

                  this.playerInstance.loadContent(episodeIndex).then(() => {
                    this.playerInstance.play().then(() => {
                      
                    });
                  });
                } else {
                  let playbackError = true;

                  if (typeof resp == "object") {
                    playbackError = resp.data;
                  }

                  this.hidePlayer();

                  // eventBus.$emit("showAuthorizeErrorMessage", {
                  //   state: true,
                  //   messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                  // });

                  throw playbackError;
                }
              }
            })
            .catch((error) => {
              
            });
        })
        .catch((error) => {
          
          this.hidePlayer();
        });
    },
    //Normal Player
    loadNextContentData(endedContentIndex) {
      let episodeIndex = endedContentIndex + 1;
      let nextepisodeid = this.episodeList[endedContentIndex + 1]
        ? this.episodeList[endedContentIndex + 1].objectid
        : "";
      localStorage.setItem("nextepisodeid", nextepisodeid);
      if (episodeIndex < this.totalEpisodeCount) {
        this.getNextEpisodeList(episodeIndex, this.episodeList, this.isMpegRequired)
          .then((resp) => {
            if (typeof resp == "object" && !resp.data.errorcode) {
              let episodeList = resp.data.data;
              this.episodeList = this.episodeList.concat(episodeList);

              let deltaPlaylist = this.buildPlayerPlaylist(this.episodeList, episodeIndex);

              this.playerInstance.appendPlaylist(deltaPlaylist, this.totalEpisodeCount);
            } else if (typeof resp == "boolean" && resp == true) {
              
            } else {
              let playbackError = true;

              if (typeof resp == "object") {
                playbackError = resp.data;
              }
              throw playbackError;
            }
            this.loadChosenIndexContent(episodeIndex, this.episodeList, this.totalEpisodeCount, false);
          })
          .catch((error) => {
            
            this.hidePlayer();
          });
      } else {
        this.hidePlayer();
      }
    },
    loadChosenContentData(chosenContentIndex) {
      
      let episodeIndex = chosenContentIndex;
      this.playerInstance.unloadPlayer();

      this.loadChosenIndexContent(episodeIndex, this.episodeList, this.totalEpisodeCount, false);
    },
    loadMoreContentData() {
      let episodeIndex = this.episodeList.length;
      this.fetchEpisodes(this.episodeList[episodeIndex - 1]).then((response) => {
        if (!response.data.errorcode) {
          let episodeList = response.data.data;
          this.episodeList = this.episodeList.concat(episodeList);

          let deltaPlaylist = this.buildPlayerPlaylist(this.episodeList, episodeIndex);

          this.playerInstance.appendPlaylist(deltaPlaylist, this.totalEpisodeCount);
        }
      });
    },
    
    actBuyDRMToPlayer(episodeIndex, episodeList, totalCount, isFirstTime = true) {
      if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
        // Call the Stream Authorize API
        this.authorizeCurrentStreamApi().then((authResponse) => {
            if(authResponse?.data?.success) {
                this.continueFlow(episodeIndex, episodeList, totalCount, isFirstTime);
            } else if(authResponse?.data?.errorcode) {
                this.hidePlayer();
                eventBus.$emit("showAuthorizeErrorMessage", {
                state: true,
                messageData: {
                    title: this.$t("Error"),
                    message: this.$t("Device Limit Reached"),
                },
                });
            }
        }).catch((error) => {alert(error)});
        return false;
      } else {
          this.continueFlow(episodeIndex, episodeList, totalCount, isFirstTime);
      }
    },
    
    buildPlayerPlaylist(episodeList, startIndex) {
      let playlist = [];
      for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(
          episodeList[aIndex],
          "watchedduration" in episodeList[aIndex] ? episodeList[aIndex].watchedduration : 0
        );
        playlist.push(playlistItem);
      }

      return playlist;
    },

    extractThumbnailPoster(contentItem) {
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;

      if (posterKey && Array.isArray(posterKey) == true && posterKey.length > 0) {
        // let posterFileList = posterKey[0].filelist;

        let posterFileList = posterKey?.find((posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE")?.filelist;

        if (posterFileList && Array.isArray(posterFileList) == true && posterFileList.length > 0) {
          let thumbNailNode = posterFileList.find((posterNode) => posterNode.quality == "THUMBNAIL");

          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode.filename;
          }
        }
      }

      return thumbNailUrl;
    },

    createPlayerPlaylistItem(content, playbackPosition) {

      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid,
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: content.streamUrl != undefined && content.streamUrl != "" ? [content.streamUrl] : [],
        licenseServer: this.appConfig?.providerDetails?.drmLicenseUrl,
        packageid: content?.playback_details ? content?.playback_details.packageid : "",
				providerid: this.appConfig.providerDetails.providerId,
        drmscheme: content?.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content?.playback_details ? content?.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        isDrmContent: content.isDrmContent,
        skip: content.skip && Array.isArray(content.skip) && content?.skip?.length > 0 ? content.skip : "",
        position: playbackPosition,
        contentTags: content?.tags,
				scrubbing: content?.scrubbing ? content?.scrubbing[0] : '',
        adversity: {
          advisory: content?.advisory,
          pgrating: content?.pgrating,
          defaultgenre: content?.defaultgenre || content?.genre
        },
        playbacktype: content?.playbacktype || "",
        contentprovider: content?.contentprovider || "",
        contentprovider: content.contentprovider || ""
      });

      if(content.category.toLowerCase() == "tvshow") {
        playlistItem.seriesTitle = content.seasonname || "";
        playlistItem.season= content.seasonnum || "";
        playlistItem.episodeNumber= content.episodenum || "";
      }
      // console.log("THIS PLAYLIST ITEM -->", playlistItem);

      return playlistItem;
    },

    fetchContentDetails(contentItem, isMpegRequired) {
      return new Promise((resolve, reject) => {
        if (contentItem.contentdetails != undefined) {
          
          resolve(true);
        } else {
          let requestData = {};
          let queryParams = {};

          queryParams.displaylanguage = localStorage.getItem("setDisplayLanguageCode");

          if (isMpegRequired == true) {
            queryParams.mpegtspackage = "YES";
          }

          requestData.contentid = contentItem.objectid;

          requestData.params = queryParams;

          resolve(store.dispatch("contentDetail", requestData));
        }
      });
    },
    
    fetchContentData(contentItem, isMpegRequired) {
      let requestData = {};
      let queryParams = {};
      queryParams.displaylanguage = localStorage.getItem("setDisplayLanguageCode");
      if (isMpegRequired == true) {
        queryParams.mpegtspackage = "YES";
      }
      requestData.contentid = contentItem.objectid;
      requestData.params = queryParams;
      return new Promise((resolve, reject) => {
        if (contentItem.contentdetails != undefined) {
          
          if(contentItem.objecttype == "CHANEL") {
            resolve(store.dispatch("contentDetail", requestData));
          } else {
            resolve(true);
          }
        } else {
          console.error('epgData', store.dispatch("contentDetail", requestData))
          resolve(store.dispatch("contentDetail", requestData));
        }
      });
    },

    fetchContentStreamUrl(contentItem, isMpegRequired) {
      return new Promise((resolve, reject) => {
        if (contentItem.streamUrl != undefined && contentItem.streamUrl != "") {
          resolve(true);
        } else {
          
          resolve(store.dispatch("contentStreamUrl", this.buildContentStreamRequest(contentItem, isMpegRequired)));
        }
      });
    },

    handleFetchContentStreamUrl(response, contentItem) {
      contentItem.streamUrl = response.data.streamfilename;
      contentItem.scrubbing = response.data.packagedfilelist && response.data.packagedfilelist.scrubbing ? response.data.packagedfilelist.scrubbing : '';

    },

    buildContentStreamRequest(contentItem, isMpegRequired) {
      let payload;
          if (!contentItem.playback_details) {
            this.hidePlayer()
          }
      payload = {
        contentid: contentItem.objectid,
        params: {
          availabilityid: contentItem.playback_details.availabilityid,
          packageid: contentItem.playback_details.packageid,
        },
      };

      if (isMpegRequired == true) {
        payload.params.mpegtspackage = "YES";
      }

      return payload;
    },
    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },

    fetchEpisodes(content) {
      return this.sendContentListRequest(content, this.isMpegRequired);
    },

    getNextEpisodeList(episodeIndex, episodeList, isMpegRequired) {
      return new Promise((resolve, reject) => {
        if (episodeIndex < episodeList.length) {
          resolve(true);
        } else {
          resolve(this.sendContentListRequest(episodeList[episodeIndex - 1], isMpegRequired));
        }
      });
    },

    sendContentListRequest(content, isMpegRequired) {
      let pageSize = 15;
      let pageNum = Math.floor(this.episodeList.length / pageSize) + 1;

      let payload = {
        objecttype: "CONTENT",
        seriesid: content.seriesid,
        seasonnum: content.seasonnum,
        page: pageNum,
        displaylanguage: localStorage.getItem("setDisplayLanguageCode"),
        pagesize: pageSize,
      };

      if (isMpegRequired == true) {
        payload.mpegtspackage = "YES";
      }
      return store.dispatch("listContent", payload);
    },

    checkEpisodeInList(objectId, episodeList) {
      let isEpisodeInList = false;

      if (episodeList.findIndex((element) => element.objectid == objectId) >= 0) {
        isEpisodeInList = true;
      }

      return isEpisodeInList;
    },
    getEpisodeIndexInList(objectId, episodeList) {
      let episodeIndex;

      episodeIndex = episodeList.findIndex((element) => element.objectid == objectId);

      return episodeIndex;
    },
    fetchEpisodeRecursively(fetchEpisodeFn, content, checkEpisodeStatusFn, isDone = false) {
      if (isDone) return true;
      let fetchPromise = fetchEpisodeFn(content);
      return fetchPromise.then((response) => {
        if (!response.data.errorcode) {
          let episodeList = response.data.data;

          if ("watchedduration" in content) {
            episodeList = this.replaceTheObjectForContinueWatch(episodeList, content);
          }

          this.totalEpisodeCount = response.data.totalcount;
          this.episodeList = this.episodeList.concat(episodeList);
          return this.fetchEpisodeRecursively(
            fetchEpisodeFn,
            content,
            checkEpisodeStatusFn,
            checkEpisodeStatusFn(content.objectid, this.episodeList)
          );
        } else {
          return true;
        }
      });
    },
    async getPurchaseListCode (content) {
      for(let list of this.purchaseList) { 

       if ( (list.objectid).toLowerCase() == (content.objectid).toLowerCase()) {
            return true;
          
       }  
      }
      return false;
    },
    async checkAvailabilityAllowsPlayback(contentItem) {
      let { availabilityList, packageList } = await this.determineContentPlanDetails(contentItem, this.availabilityList);
      let isPlaybackAllowed = false;
      let rentPurchasedActive = await this.getPurchaseListCode(contentItem);
      let subscriptions = localStorage.getItem('subscriptionDetails') && JSON.parse(localStorage.getItem('subscriptionDetails'));
      availabilityList && availabilityList.every((availability) => {
        if (this.subscriberid && (availability.pricemodel == "RENTAL" || availability.pricemodel == "PAID")) {
          if (rentPurchasedActive) {
            contentItem.isDrmContent = (packageList[0].drmscheme && packageList[0].drmscheme[0] == 'NONE') ? false : true;
            contentItem.videoType = "Content";
            contentItem.playback_details = {
              packageid: packageList[0].packageid,
              availabilityid: availability.availabilityid,
              drmscheme: packageList[0].drmscheme && packageList[0].drmscheme[0],
            };

            isPlaybackAllowed = true;
            // return true;

          }      
        } else if (availability.pricemodel === "PLAN") {
            if(this.subscriberid && subscriptions) {
              if (this.getDateForPlayback(subscriptions.nextbilling) >= this.getTodaysDate()) {
                      if (subscriptions.availabilityset.includes(availability.availabilityid)) {
                        contentItem.isDrmContent = (packageList && packageList[0]?.drmscheme && packageList[0]?.drmscheme[0] == "NONE") ? false : true;
                        contentItem.videoType = "Content";
    
                        contentItem.playback_details = {
                          packageid: packageList[0].packageid,
                          availabilityid: availability.availabilityid,
                          drmscheme: packageList[0].drmscheme && packageList[0].drmscheme[0],
                        };
    
                        isPlaybackAllowed = true;
                        // return false;
                      }
                    }
            } else if (!subscriptions) {
              if(this.subscriberid !== this.profileid) {
                eventBus.$emit("showAuthorizeErrorMessage", {
                  state: true,
                  messageData: {
                    title: "Error",
                    message: "Please authorize your subscription to access this content.",
                  },
                });
                return false;
              } else {
                if(contentItem.objecttype == "CHANEL" || contentItem.objecttype == "EPGPROGRAMME") {
                    isPlaybackAllowed = false;
                } else {
                  let payload = { listType: "onlySubscribe" };
                  eventBus.$emit("subscribePopup", payload);
                }
              }
            }
            // if (this.subscriberid && subscriptions && this.subscriptions.length > 0) {
            //   console.log("THE AVAILABLITY SUB ID -- ", this.subscriberid, this.subscriptions);

            //   this.subscriptions.every((subscription) => {
            //     if (this.getDateForPlayback(subscription.nextbilling) >= this.getTodaysDate()) {
            //       if (subscription.availabilityset.includes(availability.availabilityid)) {
            //         contentItem.isDrmContent = (packageList && packageList[0]?.drmscheme && packageList[0]?.drmscheme[0] == "NONE") ? false : true;
            //         contentItem.videoType = "Content";

            //         contentItem.playback_details = {
            //           packageid: packageList[0].packageid,
            //           availabilityid: availability.availabilityid,
            //           drmscheme: packageList[0].drmscheme && packageList[0].drmscheme[0],
            //         };

            //         isPlaybackAllowed = true;
            //         // return false;
            //       }
            //     }
            //     // return true;
            //   });
            // }  
          
            // // For Sub profiles
            // else if (this.subscriberid && this.subscriptions && this.subscriptions.length === 0 && this.subscriberid !== this.profileid) {
            //   eventBus.$emit("showAuthorizeErrorMessage", {
            //     state: true,
            //     messageData: {
            //       title: "Error",
            //       message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin"),
            //     },
            //   });

            //   return false;
            // } 
            // // For Master Profile
            // else if (this.subscriberid && this.subscriptions && this.subscriptions.length === 0) {
            //   console.log("THE REDIRECT POP up --- >");

            //   let payload = { listType: "onlySubscribe" };
            //   eventBus.$emit("subscribePopup", payload);

            // 	return true;
            // }
        } else if (availability.pricemodel == "FREE") {
          contentItem.isDrmContent = (packageList && packageList[0]?.drmscheme && packageList[0]?.drmscheme[0] == 'NONE') ? false : true;
          contentItem.videoType = "Content";

          contentItem.playback_details = {
            packageid: packageList && packageList[0]?.packageid,
            availabilityid: availability.availabilityid,
            drmscheme: packageList && packageList[0]?.drmscheme && packageList[0]?.drmscheme[0],
          };
          isPlaybackAllowed = true;
            // return true;
        }
        return isPlaybackAllowed;
      });
      console.log('isPlaybackAllowed', isPlaybackAllowed)
      return isPlaybackAllowed;
    },

    async determineContentPlanDetails(contentItem, availabilityList) {
      let contentAvailabilityList = contentItem?.contentdetails;
      let filteredAvailabilityList = [];
      let filteredPackageList = [];

      let temporaryVideoTag = document.createElement("video");
      let hasNativeHlsSupport = temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl");

      let isAndroid = navigator.userAgent.includes("Android");

      availabilityList?.every((availability) => {
        contentAvailabilityList?.every((element) => {
          let isPackageFound = false;
          if(element.mediatype === "LIVE" && !(contentItem?.contentprovider == "CATCHUPTV")) {
            filteredAvailabilityList.push(availability);
            filteredPackageList.push(element);
            isPackageFound = true;
          } else { 
              if (element.availabilityset.includes(availability.availabilityid)) {
              if (!isAndroid && hasNativeHlsSupport != "" && element.streamtype == "HLS") {
                  filteredAvailabilityList.push(availability);
                  filteredPackageList.push(element);
                  isPackageFound = true;
              } else if (element.streamtype == "DASH") {
                filteredAvailabilityList.push(availability);
                filteredPackageList.push(element);
                isPackageFound = true;
              } else if (element.streamtype == "OTHER") {
                filteredAvailabilityList.push(availability);
                filteredPackageList.push(element);
                isPackageFound = true;
              }
            }
          }
          if (isPackageFound) {
            return false;
          } else {
            return true;
          }
        });
        return true;
      })

      return { availabilityList: filteredAvailabilityList, packageList: filteredPackageList };
    },

    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },

    replaceTheObjectForContinueWatch(episodeList, content) {
      let list = episodeList;

      list.forEach((element, index) => {
        if (element.objectid == content.objectid) {
          let newObjectForTheEpisode = { ...content, ...element };

          if (index > -1) {
            list[index] = newObjectForTheEpisode;
          }
        }
      });

      return list;
    },
    
    getStreamUrlByTags(content) {
      let isSafari = true;
      let returnTag = "";
      let tags = content.tags;
      tags && tags.forEach((item) => {
        if (isSafari && item.includes('HLS:')) {
          returnTag = item;
        } else if (!isSafari && item.includes('WIDEVINE:')) {
          returnTag = item;
        }
      })
      returnTag = returnTag && returnTag.split(":").splice(1,).join(":");
      return returnTag;
    },


    async getContentProgress(content) {
      let path = '';
      let watchedduration = 0;
      if(content.seriesid) {
          path = `/subscriber/${this.subscriberid}/${this.profileid}/content/${content.seriesid}/episodes/${content.objectid}`;    
      } else {
          path = `/subscriber/${this.subscriberid}/${this.profileid}/content/${content.objectid}`;
      }
      
      try {
          const snapshot = await new Promise((resolve, reject) => {
              firebase
              .database()
              .ref(path)
              .once("value")
              .then((snapshot) => {
                  console.error("snapshot firebase:", snapshot.val());
                  resolve(snapshot);
                  }).catch((error) => {console.error("firebase error:", error);});
              });
      
          const response = snapshot.val();
          if (response) {
          if (response.status === "INPROGRESS") {
            watchedduration = response.watchedduration;
          }
          }
      } catch (error) {
          console.error("Error fetching content progress:", error);
          throw error;  // Re-throw the error to handle it outside if needed
      }
      return watchedduration;
    },

    getDateForPlayback(currentDate) {
      if (currentDate) {
        return new Date(
          `${new Date(currentDate).getFullYear()}/${new Date(currentDate).getMonth() + 1}/${new Date(
            currentDate
          ).getDate()} 23:59:59 UTC`
        );
      } else {
        return false;
      }
    },

    getTodaysDate() {
      return new Date(Date.now());
    },

    continueFlow(episodeIndex, episodeList, totalCount, isFirstTime) { 
      this.loadContentMetaData(episodeList[episodeIndex], null);
      let playlist = this.buildPlayerPlaylist(episodeList, 0);
      console.log(this.playerInstance, "this.playerInstance", playlist)
      this.playerInstance.loadPlaylist(playlist, totalCount);
      this.playerInstance.loadContent(episodeIndex).then(() => {
        this.playerInstance.play().then(() => {
          
        });
      }).catch((err) =>{alert(err); });
    }
  },
};

export default PlaybackUtilities;
